* {
  box-sizing: border-box;
}

body {
  margin: 0
}

.container {
  margin: 1rem;
  text-align: center;
}

.page_header {
  background: url('IMG_1328.png') no-repeat;
  height: 800px;
  background-position: center;
  background-size: cover;

}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #52c22c;
  text-shadow:2px 2px 4px #000 ;
  font-size: 80px; 
  transform: translate(-50%, -50%);
  font-family: fantasy;
  text-align: center;
 }

.nav {
  background-color: none;
  color: red;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  gap: 2rem;
  padding-bottom: 0;
  padding-right: 30px;
  
}

.site-title {
  font-size: 2rem;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 40px 0px 0px 40px;
  font-size: 4rem;
  font-family: fantasy;
  color: #000;
  text-shadow: 1px 1px 2px #000;
}

.nav li:hover {
  background-color: #777;
}

@media only screen and (max-device-width: 450px) {
  .nav {
    padding-right: 10px;
  }

  .nav img {
    width: 40px;
    height: 38px;
  }

  .nav a {
    font-size: 20px;
  }
}

@media only screen and (max-device-width: 410px) {
  .nav {
    padding-right: 35px;
  }

  .nav a {
    font-size: 15px;
  }
}
