div {
    width: 100%;
    margin: 0;

}

.fullpage {
    text-align: center;
    padding: 0%;
}







h2 {
    font-size: 40px;
}

h5 {
    font-size: 10px;
}

ul {
    font-size: 30px;
}